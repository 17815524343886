<template>
  <div>
    <div style="text-align: left; position: relative; top: -30px">
      <div class="row">
        <div class="col-md-12 col-lg-12" style="margin-top: 25px">
          <div class="sec-main sec-bg1 tabs" style="background: transparent; box-shadow: none; padding: 0">
            <div class="col-md-12 col-lg-12 px-0" style="min-height: 53px">
              <div v-show="!getUser" class="pt-tabs-1 tabs_register">
                <div
                  class="nav nav-tabs nav-fill nav-resp-tab"
                  id="nav-tab"
                  role="tablist"
                  style="z-index: 10"
                >
                  <a
                    class="pt-tabs nav-item nav-link active show nav-item2 tabs-new tab-resp-v tab-resp col-md-12"
                    id="nav-home-0"
                    data-toggle="tab"
                    href="#nav-0"
                    aria-controls="nav-home-0"
                    style="border: 1px solid var(--primary-color)"
                  >
                    <div>
                      <span style="display: block !important"> Crear nueva cuenta </span>
                    </div>
                  </a>

                  <a
                    class="pt-tabs nav-item nav-link nav-item2 tabs-new col-md-12 tab-resp"
                    id="nav-home-1"
                    data-toggle="tab"
                    href="#nav-1"
                    aria-controls="nav-home-1"
                    style="border: 1px solid var(--primary-color)"
                  >
                    <div>
                      <span style="display: block !important"> Tengo una cuenta </span>
                    </div>
                  </a>
                </div>
              </div>

              <div v-show="getUser" class="pt-section-title-1 mb-0" style="height: 107px">
                <h5 class="pt-section-title mb-0">Registrarse con <span class="imp-word mb-0">nosotros</span></h5>
              </div>
            </div>

            <div class="col-md-12 col-lg-12 px-0">
              <header-nav-component
                :title="'Creacion de cuenta'"
                :back_="true"
                :loading="true"
                :step="4"
                @goToCategory="goToCategory"
                style="padding-top: 0px; padding-bottom: -5px; margin-bottom: 25px"
              />
            </div>

            <div>
              <div class="col-md-12 px-0">
                <div id="order-standard_cart" style="line-height: 1.4 !important">
                  <div class="row">

                    <div class="col-md-12">
                      <div>
                        <div class="tab-content text-left" id="nav-tabContent">




                          

                          <div
                            class="tab-pane fade active show"
                            id="nav-0"
                            role="tabpanel"
                            aria-labelledby="nav-home-0"
                          >
                            <form
                              @submit.prevent="register_"
                              class="columns column is-multiline is-12"
                              data-vv-scope="form2"
                            >
                              <div class="cd-filter-block mb-5">
                                <h4>Registrarse</h4>
                                <div class="cd-filter-content">
                                  <div class="title_tabs"><small>Información Personal</small></div>
                                  <div class="row mb-5">
                                    <div class="col-md-6 mb-4">
                                      <label><i class="fas fa-user-tie"></i></label>
                                      <input
                                        v-model="form2.nombre"
                                        v-validate="'required'"
                                        name="nombre"
                                        type="text"
                                        placeholder="Nombre Completo"
                                        :class="{ 'vue-error': errors.first('form2.nombre') }"
                                        key="3"
                                        maxlength="40"
                                      />
                                      <FormError :error="errors.first('form2.nombre')"> </FormError>
                                    </div>
                                    <div class="col-md-6 mb-4">
                                      <label><i class="fa fa-id-card-o" aria-hidden="true"></i></label>
                                      <input
                                        v-model="form2.rut"
                                        @keypress="onlyNumberAndDash"
                                        v-validate="'required'"
                                        name="rut"
                                        type="text"
                                        placeholder="18465489-3"
                                        :class="{ 'vue-error': errors.first('form2.rut') }"
                                        key="4"
                                        maxlength="12"
                                      />
                                      <FormError :error="errors.first('form2.rut')"> </FormError>
                                    </div>

                                    <div class="col-md-6 mb-4">
                                      <label><i class="fas fa-envelope"></i></label>
                                      <input
                                        v-model="form2.email"
                                        v-validate="'required|email'"
                                        name="email"
                                        type="text"
                                        placeholder="Email Primario"
                                        :class="{ 'vue-error': errors.first('form2.email') }"
                                        key="5"
                                        maxlength="40"
                                      />
                                      <FormError :error="errors.first('form2.email')"> </FormError>
                                    </div>
                                    <div class="col-md-6 mb-4">
                                      <label><i class="fas fa-envelope"></i></label>
                                      <input
                                        v-model="form2.email_tecnico"
                                        v-validate="'required|email'"
                                        name="email_tecnico"
                                        data-vv-as="email técnico"
                                        type="text"
                                        placeholder="Email Técnico"
                                        :class="{ 'vue-error': errors.first('form2.email_tecnico') }"
                                        key="6"
                                        maxlength="40"
                                      />
                                      <FormError :error="errors.first('form2.email_tecnico')"> </FormError>
                                    </div>
                                    <div class="col-md-6 mb-4">
                                      <label><i class="fas fa-phone"></i></label>
                                      <input
                                        v-model="form2.telefono"
                                        @keypress="onlyNumber"
                                        v-validate="'required'"
                                        name="telefono"
                                        type="tel"
                                        placeholder="Teléfono"
                                        :class="{ 'vue-error': errors.first('form2.telefono') }"
                                        key="7"
                                        maxlength="40"
                                      />
                                      <FormError :error="errors.first('form2.telefono')"> </FormError>
                                    </div>
                                    <div class="col-md-6 mb-4">
                                      <label><i class="fas fa-map-marker-alt"></i></label>
                                      <input
                                        v-model="form2.direccion"
                                        v-validate="'required'"
                                        name="direccion"
                                        data-vv-as="dirección"
                                        type="text"
                                        placeholder="Dirección"
                                        :class="{ 'vue-error': errors.first('form2.direccion') }"
                                        key="8"
                                        maxlength="60"
                                      />
                                      <FormError :error="errors.first('form2.direccion')"> </FormError>
                                    </div>
                                  </div>
                                  <div class="title_tabs title_tabs_infoPersonal">
                                    <small>Datos de Facturación</small>

                                    <div class="empty-cart title_tabs_infoPersonal_empty-cart" style="margin: -20px 0px 0 0">
                                      <a
                                        @click="transferData"
                                        type="button"
                                        class="btn btn-link btn-xs btn-empty-v"
                                        style="line-height: 25px !important"
                                      >
                                        <i class="fas fa-user-tie"></i>
                                        <span> Copiar información personal</span>
                                      </a>
                                    </div>
                                  </div>
                                  <div class="row mb-5">
                                    <div class="col-md-6 mb-4">
                                      <label><i class="fas fa-building"></i></label>
                                      <input
                                        v-model="form2.facturacion_nombre"
                                        v-validate="'required'"
                                        name="facturacion_nombre"
                                        data-vv-as="facturación nombre"
                                        type="text"
                                        placeholder="Nombre facturación"
                                        :class="{ 'vue-error': errors.first('form2.facturacion_nombre') }"
                                        key="9"
                                        maxlength="40"
                                      />

                                      <FormError :error="errors.first('form2.facturacion_nombre')">
                                      </FormError>
                                    </div>
                                    <div class="col-md-6 mb-4">
                                      <label><i class="far fa-building"></i></label>
                                      <input
                                        v-model="form2.facturacion_rut"
                                        @keypress="onlyNumberAndDash"
                                        v-validate="'required'"
                                        name="facturacion_rut"
                                        data-vv-as="facturación rut"
                                        type="text"
                                        placeholder="18465489-3"
                                        :class="{ 'vue-error': errors.first('form2.facturacion_rut') }"
                                        key="10"
                                        maxlength="12"
                                      />
                                      <FormError :error="errors.first('form2.facturacion_rut')"> </FormError>
                                    </div>
                                    <div class="col-md-6 mb-4">
                                      <label><i class="fas fa-envelope"></i></label>
                                      <input
                                        v-model="form2.facturacion_email"
                                        v-validate="'required|email'"
                                        name="facturacion_email"
                                        data-vv-as="facturación email"
                                        type="text"
                                        placeholder="Email facturación"
                                        :class="{ 'vue-error': errors.first('form2.facturacion_email') }"
                                        key="11"
                                        maxlength="40"
                                      />
                                      <FormError :error="errors.first('form2.facturacion_email')">
                                      </FormError>
                                    </div>
                                    <div class="col-md-6">
                                      <label><i class="fas fa-phone"></i></label>
                                      <input
                                        v-model="form2.facturacion_telefono"
                                        v-validate="'required'"
                                        name="facturacion_telefono"
                                        @keypress="onlyNumber"
                                        data-vv-as="facturación teléfono"
                                        type="text"
                                        placeholder="Teléfono facturación"
                                        :class="{ 'vue-error': errors.first('form2.facturacion_telefono') }"
                                        key="12"
                                        maxlength="40"
                                      />
                                      <FormError :error="errors.first('form2.facturacion_telefono')">
                                      </FormError>
                                    </div>
                                    <div class="col-md-6 mb-4">
                                      <label><i class="fas fa-map-marker-alt"></i></label>
                                      <input
                                        v-model="form2.facturacion_direccion"
                                        v-validate="'required'"
                                        name="facturacion_direccion"
                                        data-vv-as="facturación dirección"
                                        type="text"
                                        placeholder="Dirección facturación"
                                        :class="{ 'vue-error': errors.first('form2.facturacion_direccion') }"
                                        key="13"
                                        maxlength="40"
                                      />
                                      <FormError :error="errors.first('form2.facturacion_direccion')">
                                      </FormError>
                                    </div>
                                    <div class="col-md-6 mb-4">
                                      <select id="facturacion_region" 
                                      v-model="form2.facturacion_region"
                                      v-validate="'required'"
                                      name="facturacion_region"
                                      aria-placeholder="Región"
                                      placeholder="Region facturación"
                                      @change="City_Updater()"
                                      class="aparence-none-v input form-control"
                                      style="border: none; background: var(--grey-color);"

                                      >
                                      <option value="" disabled selected>Región facturación</option>
                                        <option v-for="(region, idx) in Regiones_" :value="region" :key="idx">
                                          {{ region }}
                                        </option>
                                      </select>
                                      <FormError :error="errors.first('form2.facturacion_region')">
                                      </FormError>
                                    </div>
                                    <div class="col-md-6 mb-4">
                                      <select 
                                        v-validate="'required'"
                                        id="facturacion_comuna" 
                                        v-model="form2.facturacion_comuna"
                                        class="aparence-none-v input form-control"
                                        style="border: none; background: var(--grey-color);"
                                        @change="City_Check()"
                                        name="facturacion_comuna"

                                        >
                                        <option value="" disabled selected>Comuna facturación</option>
                                        <option v-for="(comuna, idx) in Comunas_" 
                                          :value="comuna" 
                                          :key="idx">
                                          {{ comuna }}
                                        </option>
                                      </select>
                                      <FormError :error="errors.first('form2.facturacion_comuna')">
                                      </FormError>
                                    </div>
                                    <div class="col-md-6 mb-4">
                                      <label><i class="fas fa-building"></i></label>
                                      <input
                                        v-model="form2.facturacion_giro"
                                        v-validate="'required'"
                                        name="facturacion_giro"
                                        data-vv-as="facturación giro"
                                        type="text"
                                        placeholder="Giro facturación"
                                        :class="{ 'vue-error': errors.first('form2.facturacion_giro') }"
                                        key="16"
                                        maxlength="40"
                                      />
                                      <FormError :error="errors.first('form2.facturacion_giro')">
                                      </FormError>
                                    </div>





                                  </div>
                                  <div class="title_tabs"><small>Cuenta y Seguridad</small></div>
                                  <div class="row mb-5">
                                    <div class="col-md-6 mb-4">
                                      <label><i class="fas fa-user-tie"></i></label>
                                      <input
                                        v-model="form2.username"
                                        v-validate="'required'"
                                        name="username"
                                        type="text"
                                        placeholder="Usuario o RUT (sin puntos y con guión)"
                                        :class="{ 'vue-error': errors.first('form2.username') }"
                                        key="14"
                                        maxlength="30"
                                      />
                                      <FormError :error="errors.first('form2.username')"> </FormError>
                                    </div>
                                    <div class="col-md-6 d-none-resp"></div>
                                    <div class="col-md-6 mb-4">
                                      <label><i class="fas fa-lock"></i></label>
                                      <input
                                        v-model="form2.pass"
                                        v-validate="'required'"
                                        name="pass"
                                        ref="password"
                                        data-vv-as="contraseña"
                                        type="password"
                                        placeholder="Contraseña"
                                        :class="{ 'vue-error': errors.first('form2.pass') }"
                                        key="15"
                                        maxlength="50"
                                      />

                                      <!-- <password v-model="form2.pass"/> -->
                                      <FormError :error="errors.first('form2.pass')"> </FormError>
                                      <password v-model="form2.pass" :strength-meter-only="true" />
                                    </div>
                                    <div class="col-md-6 mb-4">
                                      <label><i class="fas fa-lock"></i></label>
                                      <input
                                        v-model="form2.repass"
                                        v-validate="'required|confirmed:password'"
                                        name="repass"
                                        data-vv-as="confirmar contraseña"
                                        type="password"
                                        placeholder="Confirme Contraseña"
                                        :class="{ 'vue-error': errors.first('form2.repass') }"
                                        key="16_"
                                        maxlength="50"
                                      />
                                      <FormError :error="errors.first('form2.repass')"> </FormError>
                                      <password v-model="form2.repass" :strength-meter-only="true" />
                                    </div>
                                    <div class="col-md-12">
                                      <button
                                        type="submit"
                                        alt="Completar registro" aria-label="Completar registro"
                                        class="btn btn-default-grad-purple-fill btn-resp-v mb-1 mt-3"
                                        style="float: right; min-width: 180px; padding: 5px 20px !important"
                                        :class="[verify ? 'verify-class' : 'normal-class']"
                                      >
                                        <span v-if="!verify" class="spinner-span"
                                          >Completar registro
                                          <i
                                            class="fas fa-arrow-alt-circle-right spinner-i"
                                            style="top: 9px"
                                          ></i
                                        ></span>
                                        <div
                                          v-else
                                          class="spinner-border text-secondary"
                                          style="max-height: 30px"
                                          role="status"
                                          
                                        >
                                          <span class="sr-only">Loading...</span>
                                        </div>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>

                          <div
                            class="tab-pane fade"
                            id="nav-1"
                            role="tabpanel"
                            aria-labelledby="nav-home-1"
                          >
                            <form
                              @submit.prevent="login_"
                              class="columns column is-multiline is-12"
                              data-vv-scope="form1"
                            >
                              <div v-show="!getUser" class="cd-filter-block">
                                <h4 style="margin-bottom: 10px">Login Usuario Existente</h4>
                                <div class="cd-filter-content">
                                  <div class="row" style="margin-bottom: 20px">
                                    <div class="col-md-6" style="margin-bottom: 10px">
                                      <label><i class="fas fa-envelope"></i></label>
                                      <input
                                        v-model="form1.rut_username"
                                        v-validate="'required'"
                                        type="text"
                                        name="rut_username"
                                        data-vv-as="username o rut"
                                        placeholder="Usuario o RUT (sin puntos y con guión)"
                                        :class="{ 'vue-error': errors.first('form1.rut_username') }"
                                        key="1_"
                                        maxlength="30"
                                      />
                                      <FormError :error="errors.first('form1.rut_username')"> </FormError>
                                    </div>

                                    <div class="col-md-6" style="margin-bottom: 10px">
                                      <label><i class="fas fa-lock"></i></label>
                                      <input
                                        v-model="form1.contrasena"
                                        v-validate="'required'"
                                        type="password"
                                        name="contrasena"
                                        data-vv-as="contraseña"
                                        placeholder="Contraseña"
                                        :class="{ 'vue-error': errors.first('form1.contrasena') }"
                                        key="2_"
                                        maxlength="50"
                                      />
                                      <FormError :error="errors.first('form1.contrasena')"> </FormError>
                                    </div>
                                    <div class="col-md-12">
                                      <button
                                        type="submit"
                                        alt="iniciar session" aria-label="iniciar session"
                                        class="btn btn-default-grad-purple-fill btn-resp-v mb-1 mt-3"
                                        style="float: right; min-width: 180px; padding: 5px 20px !important"
                                        :class="[verify ? 'verify-class' : 'normal-class']"
                                      >
                                        <span v-if="!verify" class="spinner-span"
                                          >Ingresar
                                          <i
                                            class="fas fa-arrow-alt-circle-right spinner-i"
                                            style="top: 9px"
                                          ></i
                                        ></span>
                                        <div
                                          v-else
                                          class="spinner-border text-secondary"
                                          style="max-height: 30px"
                                          role="status"
                                        >
                                          <span class="sr-only">Loading...</span>
                                        </div>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>



                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-12"></div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import HeaderNavComponent from "../HeaderNavComponent";
import FormError from "../FormError";
import Password from "vue-password-strength-meter";
import {
  formatNumber,
  toArray,
  setPriceService,
  setTotalPriceService,
  setMasterTotalPriceService,
  returnQuantityService,
  changeSeparator,
  returnAllServices,
  returnCantidadService,
  onlyNumberAndDash,
  onlyNumber,
} from "@/helpers";
export default {
  components: {
    Password,
    HeaderNavComponent,
    FormError,
  },
  data: () => ({
    verify: false,
    form1: {
      rut_username: "",
      contrasena: "",
    },
    form2: {
      nombre: "",
      rut: "",
      email: "",
      direccion: "",
      telefono: "",
      email_tecnico: "",

      facturacion_nombre: "",
      facturacion_rut: "",
      facturacion_email: "",
      facturacion_direccion: "",
      facturacion_telefono: "",
      facturacion_region: "",
      facturacion_comuna: "",
      facturacion_giro: "",
      username: "",
      pass: "",
      repass: "",
    },
    Regiones_: [],
    Comunas_: []
  }),
  computed: {
    ...mapGetters(["getUser", "getResume"]),
  },
  created(){
    this.Regiones_ = this.Regiones_Ciudades();
  },
  // watch: {
  //   "form2.repass"(val) {
  //     if (val !== this.form2.pass) {
  //       console.log(val);
  //       this.$validator.errors.add({
  //         field: "repass",
  //         msg: "Las contraseñas no coinciden",
  //         scope: "form2",
  //       });
  //     }
  //   },
  // },
  methods: {
    ...mapMutations(["logout", "setSelected"]),
    ...mapActions(["login", "register"]),
    formatNumber,
    toArray,
    setPriceService,
    setTotalPriceService,
    setMasterTotalPriceService,
    returnQuantityService,
    changeSeparator,
    returnAllServices,
    returnCantidadService,
    onlyNumberAndDash,
    onlyNumber,
    transferData() {
      this.$Progress.start();
      this.form2.facturacion_nombre = this.form2.nombre;
      this.form2.facturacion_rut = this.form2.rut;
      this.form2.facturacion_email = this.form2.email;
      this.form2.facturacion_direccion = this.form2.direccion;
      this.form2.facturacion_telefono = this.form2.telefono;
      this.$Progress.finish();
      this.$toast.success("Información copiada.");
    },
    editItem(index) {
      this.$emit("edit", index);
    },
    deleteService_(index) {
      this.$root.$emit("deleteService", index);
    },
    back() {
      this.$emit("back");
    },
    login_() {
      this.$Progress.start();
      this.$validator.validateAll("form1").then((esValido) => {
        if (esValido) {
          this.verify = true;
          this.login(this.form1).then((res) => {
            if (res?.type == "success") {
              this.$toast.success(res?.cliente?.nombre + " Logeado correctamente.");
              this.$Progress.finish();
            } else {
              this.$validator.errors.add({
                field: "rut_username",
                msg: res.message,
                scope: "form1",
              });

              this.$toast.error("Error en los campos ingresados");
              this.$Progress.fail();
            }
            this.verify = false;
          });
        } else {
          this.$toast.error("Error de login");
          this.$Progress.fail();
          this.verify = false;
        }
        //
      });
    },
    logout_() {
      this.logout();
    },
    goToCategory() {
      this.$emit("goToCategory");
    },

    register_() {
      this.$Progress.start();
      this.$validator.validateAll("form2").then((esValido) => {
        if (esValido) {
          this.verify = true;
          this.register(this.form2).then((res) => {
            if (res?.type == "success") {
              var nav0 = document.getElementById("nav-0");
              var nav1 = document.getElementById("nav-1");
              nav1.classList.remove("active");
              nav1.classList.remove("show");
              nav0.classList.add("active");
              nav0.classList.add("show");
              window.scrollTo(0, 0);
              this.$toast.success(res?.cliente[0]?.nombre + " Registrado correctamente.");
              this.$Progress.finish();
              this.$router.push('/');
            } else {
              for (const [index, value] of Object.entries(res)) {
                this.$validator.errors.add({
                  field: index,
                  msg: value[0],
                  scope: "form2",
                });
              }

              this.$toast.error("Error en los campos ingresados");
              this.$Progress.fail();
            }
            this.verify = false;
          });
        } else {
          this.$toast.error("Error de registro");
          this.$Progress.fail();
          this.verify = false;
        }
      });
    },
    City_Updater(){
      if(this.form2.facturacion_region != ""){
        let idx=0;
        let delreg=true;
        this.Regiones_.forEach(reg => {
          if(this.form2.facturacion_region == reg){
            this.Comunas_ = this.Regiones_Ciudades(idx);
            delreg=false;
          }
          idx++;
        });

        if(delreg){
          this.form2.facturacion_region = "";
          this.form2.facturacion_comuna = "";
        }
      }
    },
    City_Check(){
      if(this.form2.facturacion_comuna != ""){
        let delcom=true;
        this.Comunas_.forEach(com => {
          if(this.form2.facturacion_comuna == com){
            delcom=false;
          }
        });

        if(delcom){
          this.form2.facturacion_comuna = "";
        }
      }
    },
    Regiones_Ciudades(region=-1, ciudad=-1){
      let Chile = [
        {
          "NombreRegion": "Arica y Parinacota",
          "comunas": ["Arica", "Camarones", "Putre", "General Lagos"]
        },{
          "NombreRegion": "Tarapacá",
          "comunas": ["Iquique", "Alto Hospicio", "Pozo Almonte", "Camiña", "Colchane", "Huara", "Pica"]
        },{
          "NombreRegion": "Antofagasta",
          "comunas": ["Antofagasta", "Mejillones", "Sierra Gorda", "Taltal", "Calama", "Ollagüe", "San Pedro de Atacama", "Tocopilla", "María Elena"]
        },{
          "NombreRegion": "Atacama",
          "comunas": ["Copiapó", "Caldera", "Tierra Amarilla", "Chañaral", "Diego de Almagro", "Vallenar", "Alto del Carmen", "Freirina", "Huasco"]
        },{
          "NombreRegion": "Coquimbo",
          "comunas": ["La Serena", "Coquimbo", "Andacollo", "La Higuera", "Paiguano", "Vicuña", "Illapel", "Canela", "Los Vilos", "Salamanca", "Ovalle", "Combarbalá", "Monte Patria", "Punitaqui", "Río Hurtado"]
        },{
          "NombreRegion": "Valparaíso",
          "comunas": ["Valparaíso", "Casablanca", "Concón", "Juan Fernández", "Puchuncaví", "Quintero", "Viña del Mar", "Isla de Pascua", "Los Andes", "Calle Larga", "Rinconada", "San Esteban", "La Ligua", "Cabildo", "Papudo", "Petorca", "Zapallar", "Quillota", "Calera", "Hijuelas", "La Cruz", "Nogales", "San Antonio", "Algarrobo", "Cartagena", "El Quisco", "El Tabo", "Santo Domingo", "San Felipe", "Catemu", "Llaillay", "Panquehue", "Putaendo", "Santa María", "Quilpué", "Limache", "Olmué", "Villa Alemana"]
        },{
          "NombreRegion": "Región del Libertador Gral. Bernardo O’Higgins",
          "comunas": ["Rancagua", "Codegua", "Coinco", "Coltauco", "Doñihue", "Graneros", "Las Cabras", "Machalí", "Malloa", "Mostazal", "Olivar", "Peumo", "Pichidegua", "Quinta de Tilcoco", "Rengo", "Requínoa", "San Vicente", "Pichilemu", "La Estrella", "Litueche", "Marchihue", "Navidad", "Paredones", "San Fernando", "Chépica", "Chimbarongo", "Lolol", "Nancagua", "Palmilla", "Peralillo", "Placilla", "Pumanque", "Santa Cruz"]
        },{
          "NombreRegion": "Región del Maule",
          "comunas": ["Talca", "Constitución", "Curepto", "Empedrado", "Maule", "Pelarco", "Pencahue", "Río Claro", "San Clemente", "San Rafael", "Cauquenes", "Chanco", "Pelluhue", "Curicó", "Hualañé", "Licantén", "Molina", "Rauco", "Romeral", "Sagrada Familia", "Teno", "Vichuquén", "Linares", "Colbún", "Longaví", "Parral", "Retiro", "San Javier", "Villa Alegre", "Yerbas Buenas"]
        },{
          "NombreRegion": "Región del Biobío",
          "comunas": ["Concepción", "Coronel", "Chiguayante", "Florida", "Hualqui", "Lota", "Penco", "San Pedro de la Paz", "Santa Juana", "Talcahuano", "Tomé", "Hualpén", "Lebu", "Arauco", "Cañete", "Contulmo", "Curanilahue", "Los Álamos", "Tirúa", "Los Ángeles", "Antuco", "Cabrero", "Laja", "Mulchén", "Nacimiento", "Negrete", "Quilaco", "Quilleco", "San Rosendo", "Santa Bárbara", "Tucapel", "Yumbel", "Alto Biobío", "Chillán", "Bulnes", "Cobquecura", "Coelemu", "Coihueco", "Chillán Viejo", "El Carmen", "Ninhue", "Ñiquén", "Pemuco", "Pinto", "Portezuelo", "Quillón", "Quirihue", "Ránquil", "San Carlos", "San Fabián", "San Ignacio", "San Nicolás", "Treguaco", "Yungay"]
        },{
          "NombreRegion": "Región de la Araucanía",
          "comunas": ["Temuco", "Carahue", "Cunco", "Curarrehue", "Freire", "Galvarino", "Gorbea", "Lautaro", "Loncoche", "Melipeuco", "Nueva Imperial", "Padre las Casas", "Perquenco", "Pitrufquén", "Pucón", "Saavedra", "Teodoro Schmidt", "Toltén", "Vilcún", "Villarrica", "Cholchol", "Angol", "Collipulli", "Curacautín", "Ercilla", "Lonquimay", "Los Sauces", "Lumaco", "Purén", "Renaico", "Traiguén", "Victoria", ]
        },{
          "NombreRegion": "Región de Los Ríos",
          "comunas": ["Valdivia", "Corral", "Lanco", "Los Lagos", "Máfil", "Mariquina", "Paillaco", "Panguipulli", "La Unión", "Futrono", "Lago Ranco", "Río Bueno"]
        },{
          "NombreRegion": "Región de Los Lagos",
          "comunas": ["Puerto Montt", "Calbuco", "Cochamó", "Fresia", "Frutillar", "Los Muermos", "Llanquihue", "Maullín", "Puerto Varas", "Castro", "Ancud", "Chonchi", "Curaco de Vélez", "Dalcahue", "Puqueldón", "Queilén", "Quellón", "Quemchi", "Quinchao", "Osorno", "Puerto Octay", "Purranque", "Puyehue", "Río Negro", "San Juan de la Costa", "San Pablo", "Chaitén", "Futaleufú", "Hualaihué", "Palena"]
        },{
          "NombreRegion": "Región Aisén del Gral. Carlos Ibáñez del Campo",
          "comunas": ["Coihaique", "Lago Verde", "Aisén", "Cisnes", "Guaitecas", "Cochrane", "O’Higgins", "Tortel", "Chile Chico", "Río Ibáñez"]
        },{
          "NombreRegion": "Región de Magallanes y de la Antártica Chilena",
          "comunas": ["Punta Arenas", "Laguna Blanca", "Río Verde", "San Gregorio", "Cabo de Hornos (Ex Navarino)", "Antártica", "Porvenir", "Primavera", "Timaukel", "Natales", "Torres del Paine"]
        },{
          "NombreRegion": "Región Metropolitana de Santiago",
          "comunas": ["Cerrillos", "Cerro Navia", "Conchalí", "El Bosque", "Estación Central", "Huechuraba", "Independencia", "La Cisterna", "La Florida", "La Granja", "La Pintana", "La Reina", "Las Condes", "Lo Barnechea", "Lo Espejo", "Lo Prado", "Macul", "Maipú", "Ñuñoa", "Pedro Aguirre Cerda", "Peñalolén", "Providencia", "Pudahuel", "Quilicura", "Quinta Normal", "Recoleta", "Renca", "San Joaquín", "San Miguel", "San Ramón", "Santiago", "Vitacura", "Puente Alto", "Pirque", "San José de Maipo", "Colina", "Lampa", "Tiltil", "San Bernardo", "Buin", "Calera de Tango", "Paine", "Melipilla", "Alhué", "Curacaví", "María Pinto", "San Pedro", "Talagante", "El Monte", "Isla de Maipo", "Padre Hurtado", "Peñaflor"]
        }
      ]
      
      let regiones = [];
      if(region == -1){
        Chile.forEach(reg => {
          regiones.push(reg.NombreRegion);
        });
        return regiones;
      }

      if(region > -1){
        return Chile[region].comunas;
      }

      if((region > -1) && (ciudad > -1)){
        return Chile[region].comunas[ciudad];
      }
    }
  },
};
</script>

<style scoped>
.vue-error {
  border-color: #dc3545 !important;
}
.title-bar-left2 {
  text-align: left;
  margin-top: 33px;
  padding: 10px 30px;
  font-size: 17px;
}

.title-bar-right2 {
  text-align: right;
  margin-top: 33px;
  padding: 10px 30px;
  font-size: 17px;
}

.cd-filter-block label i {
  top: 20px !important;
}

.title_tabs {
  margin-bottom: 15px;
}

.cd-filter-block .col-md-6 {
  margin-bottom: 10px;
}

.check-container {
  box-shadow: 0 5px 15px 0 rgb(110 110 110 / 10%);
  padding: 65px 80px;
  background: white;
  border-radius: 15px;
  margin-right: 0px;
  margin-left: 0px;
}

@media(max-width:600px){
.tabs_register{
  margin: 55px 0px;
}

.title_tabs_infoPersonal{
    display: flex;
    flex-direction: column-reverse;
    gap: 15px;
  }
  .title_tabs_infoPersonal_empty-cart{
    text-align: left !important;
    margin: 0px !important;
  }
}
</style>
