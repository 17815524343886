<template>
  <div>
    <div class="ordersummary mt-0">
      <div style="width: 100%; display: flex; justify-content: space-between">
        <h4
          data-toggle="collapse"
          id="heading_collapse"
          href="#panel_1"
          ref="panel_1"
          role="button"
          aria-expanded="true"
          aria-controls="panel_1"
          style="text-align: left; padding-left: 0px; font-size: 20px; margin-bottom: 9px; padding-left: 5px"
        >
          PLAN SELECCIONADO 
        </h4>
      </div>
    </div>

    <div
      id="panel_1"
      class="collapse fade-in included c-grey"
      :class="[showAttributes ? 'show' : '']"
      style="text-align: left; transition: ease 0.5s"
    >
      <div>
        <ul v-for="item in attributes" :key="item.id" style="margin-bottom: 0.2rem; padding-left: 5px">
          <li>
            <i class="ion ion-android-done mr-1"></i>
            {{ item.nombre }}: {{ item.pivot.valor }}
          </li>
        </ul>
      </div>
    </div>

    <div id="order-standard_cart" style="line-height: 1.4 !important">
      <div class="row">
        <div class="col-md-12">
          <div class="mb-1">
            <b v-if="cupon_query" class="text_describe_cupon">
                      <!-- icon cupon start -->
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="sparkle">
                        <path class="path" stroke-linejoin="round" stroke-linecap="round" stroke="black" fill="black" d="M14.187 8.096L15 5.25L15.813 8.096C16.0231 8.83114 16.4171 9.50062 16.9577 10.0413C17.4984 10.5819 18.1679 10.9759 18.903 11.186L21.75 12L18.904 12.813C18.1689 13.0231 17.4994 13.4171 16.9587 13.9577C16.4181 14.4984 16.0241 15.1679 15.814 15.903L15 18.75L14.187 15.904C13.9769 15.1689 13.5829 14.4994 13.0423 13.9587C12.5016 13.4181 11.8321 13.0241 11.097 12.814L8.25 12L11.096 11.187C11.8311 10.9769 12.5006 10.5829 13.0413 10.0423C13.5819 9.50162 13.9759 8.83214 14.186 8.097L14.187 8.096Z">
                        </path>
                        <path class="path" stroke-linejoin="round" stroke-linecap="round" stroke="black" fill="black" d="M6 14.25L5.741 15.285C5.59267 15.8785 5.28579 16.4206 4.85319 16.8532C4.42059 17.2858 3.87853 17.5927 3.285 17.741L2.25 18L3.285 18.259C3.87853 18.4073 4.42059 18.7142 4.85319 19.1468C5.28579 19.5794 5.59267 20.1215 5.741 20.715L6 21.75L6.259 20.715C6.40725 20.1216 6.71398 19.5796 7.14639 19.147C7.5788 18.7144 8.12065 18.4075 8.714 18.259L9.75 18L8.714 17.741C8.12065 17.5925 7.5788 17.2856 7.14639 16.853C6.71398 16.4204 6.40725 15.8784 6.259 15.285L6 14.25Z">
                        </path>
                        <path class="path" stroke-linejoin="round" stroke-linecap="round" stroke="black" fill="black" d="M6.5 4L6.303 4.5915C6.24777 4.75718 6.15472 4.90774 6.03123 5.03123C5.90774 5.15472 5.75718 5.24777 5.5915 5.303L5 5.5L5.5915 5.697C5.75718 5.75223 5.90774 5.84528 6.03123 5.96877C6.15472 6.09226 6.24777 6.24282 6.303 6.4085L6.5 7L6.697 6.4085C6.75223 6.24282 6.84528 6.09226 6.96877 5.96877C7.09226 5.84528 7.24282 5.75223 7.4085 5.697L8 5.5L7.4085 5.303C7.24282 5.24777 7.09226 5.15472 6.96877 5.03123C6.84528 4.90774 6.75223 4.75718 6.697 4.5915L6.5 4Z">
                        </path>
                      </svg>
                                    <!-- icon cupon end -->
                        Continua para validar cupón.
                    </b>
            <div class="view-cart-items-header">
              <div class="row" style="flex-flow: row nowrap">
                <div class="col-lg-7 col-sm-7 col-xs-7">Servicios/Opciones</div>
                <div class="col-lg-5 col-sm-5 text-right">Precio/Ciclo</div>
              </div>
            </div>

            <div class="view-cart-items">
              <div class="item box_service">
                <div class="row">
                  <div class="col-md-12">


                    <span style="display: block">
                      <div style="font-size: 1.6em">
                        {{ detail_computed.nombre }}
                        <button
                          v-if="step_config == 2"
                          alt="Editar servicio" aria-label="Editar servicio"
                          @click="returnStepConfg"
                          data-toggle="collapse"
                          href="#config_01"
                          role="button"
                          aria-expanded="true"
                          aria-controls="config_01"
                          aria-hidden="true"
                          class="btn btn-link btn-xs fix-btn-table-c-v ml-1 btn-color-edit-v"
                        >
                          <i class="fa fa-pencil-square-o"></i>
                          Editar
                        </button>
                        <button
                          class="btn btn-link btn-xs fix-btn-table-c-v btn-color-delete-v box_service_btn_deleteService"
                          @click="back()"
                        >
                          <i class="fas fa-times"></i>
                         
                        </button>
                      </div>
                    </span>
                    

                    <span class="item-group">{{ detail_computed.subcategory_.nombre }}</span>
                    <div v-if="detail_computed.estado == 1" class="mt-1">
                      <div v-for="(configuracion, name) in detail_computed.configuracion" :key="name">
                        <span class="item-domain" style="color: var(--primary-color)">
                          {{ configuracion.nombre }}: {{ configuracion.value }}
                        </span>
                      </div>
                    </div>
                    <div v-else class="mt-1">
                      <span>{{ detail_computed.nombre }} <br /><b v-if="detail_computed.estado == 0">(Sin configurar)</b></span>
                    </div>
                  </div>

                  <div class="col-md-12 col-lg-12 px-0 pt-pricebox-info price-card-new price-cont pr-3">
                    <h6 class="price" style="text-align: right">
                      <div v-if="detail_computed.cupon_service" class="priceAndCupon">
                        <b v-if="detail_computed.cupon_service.periodicidad_state == 1" class="priceAndCupon_priceBeforeCupon">
                           ${{ formatNumber( setPriceService( detail_computed.moneda, (detail_computed.precio *  get_valor_por_nombre_periodicidad(detail_computed.cupon_service.cupon_periodicidad_selected.nombre) ) ) ) }}
                        </b>
                        <b v-else class="priceAndCupon_priceBeforeCupon">
                          ${{ formatNumber( setPriceService( detail_computed.moneda, detail_computed.precio ) ) }}
                        </b>
                          <span>
                             ${{formatNumber( setPriceService( detail_computed.moneda, getCalculo_price_with_cupon(detail_computed.cupon_service, detail_computed.precio  ) ) ) }}
                        </span>
                      </div>

                      <span v-else
                        >${{
                          formatNumber(
                            setPriceService(detail_computed.moneda, detail_computed.precio) 
                          )
                        }}
                      </span>
                    </h6>
                    <p v-if="detail_computed.cupon_service && detail_computed.cupon_service.periodicidad_state == 1 && detail_computed.cupon_service.cupon_periodicidad_selected" class="pt-price-duration p-price-duration-v">
                      CLP / {{ detail_computed.cupon_service.cupon_periodicidad_selected.nombre }} - Primer pago
                    </p>
                    <p v-else class="pt-price-duration p-price-duration-v">
                      CLP / {{ detail_computed.periodicidad.nombre }}
                    </p>
                  </div>
                </div>
              </div>
              <!-- complementos --------------------------------------------------------------------------------- -->
              <div v-for="(item, index) in detailServiceConfig" :key="index" class="item box_service"> 
                <div class="row">
                  <div class="col-md-12">
                    <span style="display: block; width: 90%;">
                      <div style="font-size: 1.6em">
                        <b v-if="item.configuracion.cantidad" class="count-dynamic">{{
                          returnQuantityService(item.configuracion)
                        }}</b
                        >{{ item.nombre }}

                        <button
                          v-if="item.nivel == 1"
                          alt="Editar servicio de configuracion" aria-label="Editar servicio de configuracion"
                          class="btn btn-link btn-xs fix-btn-table-c-v ml-1 btn-color-edit-v"
                        >
                          <i class="fa fa-pencil-square-o"></i>
                          Editar
                        </button>

                        <button
                        v-if="item.nivel == 1"
                          alt="Eliminar servicio de configuracion" aria-label="Eliminar servicio de configuracion"                       
                          v-tooltip="'Eliminar servicio'"
                          data-toggle="modal"
                          data-target="#myModal"
                          class="btn btn-link btn-xs fix-btn-table-c-v btn-color-delete-v"
                        >
                          <i class="fas fa-times"></i>
                        
                        </button>
                        <button
                        v-if="item.nivel != 1"
                        @click="deleteDetail({service: item, index: index})"
                        alt="Eliminar otro servicio de configuracion" aria-label="Eliminar otro servicio de configuracion"
                          v-tooltip="'Eliminar servicio'"
                          class="btn btn-link btn-xs fix-btn-table-c-v btn-color-delete-v box_service_btn_deleteService"
                        >
                          <i class="fas fa-times"></i>
                        </button>
                      </div>
                    </span>

                    <span v-if="item.subcategory_" class="item-group">{{ item.subcategory_.nombre }}</span>
                    <span v-else class="item-group"> Complemento </span>

                    <div v-if="!item.configuracion.cantidad && !item.producto" class="mt-1">
                      <div v-for="(configuracion, name) in item.configuracion" :key="name">
                        <span class="item-domain" style="color: var(--primary-color)">
                          {{ configuracion.nombre }}: {{ configuracion.value }} 
                        </span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 col-lg-12 px-0 pt-pricebox-info price-card-new price-cont pr-3">
                    <h6 class="price" style="text-align: right">
                      <span
                        >${{
                          formatNumber(
                            setPriceService(item.moneda, item.precio * returnCantidadService(item))
                          )
                        }}
                      </span>
                    </h6>
                    <p class="pt-price-duration p-price-duration-v">CLP / {{ item.periodicidad.nombre }}</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12 price-total-table px-2">
              <h6 style="font-size: 28px; white-space: nowrap">
                <b>${{ formatNumber(setTotalPriceService(detail)) }}</b>
              </h6>
            </div>
            <br/>



          <!-- <div v-if="detail.category_.id == 17" class="alert alert-light alert-payment-v" role="alert">
            - Al continuar, para utilizar los días de prueba del hosting, debe pagar con nuestra opción de transferencia.
            <br/>
          </div> -->


          <!-- {{ detail.domain_ }} -->
          <button
          v-if="detail.domain_"
            @click="$emit('goToCheckout')"
            alt="continuar" aria-label="continuar"
            type="button"
            class="btn btn-default-grad-purple-fill-black"
            style="float: right; width: 100%; height: 52px; padding: 8px 45px !important"
          >
            <span class="spinner-span"
              >Continuar <i class="fas fa-arrow-alt-circle-right ml-2" style="position: relative; top: 9px"></i
            ></span>
          </button>
   
            <!-- FLECHA SCROLL DOWN -->
            <!-- <transition name="slide-fade" tag="div" class="row row-arrow">
              
              <div
                v-show="showNavbar && step_config == 2 && filterCategory.length > 0"
                class="pos-arrow row-arrow"
              >
                <a class="gocontent godown godown2 gocheck" @click="scrollDown()">
                  <img
                    class="svg"
                    style="left: 105px; position: relative"
                    src="images/arrow_down.png"
                    height="80"
                    v-tooltip="'Click para bajar'"
                  />
                  <span style="width: 235px; height: 123px; text-align: center"
                    >Para continuar desplácese al final de la pagina</span
                  >
                </a>
              </div>
            </transition> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import {
  formatNumber,
  toArray,
  setPriceService,
  setTotalPriceService,
  setMasterTotalPriceService,
  returnQuantityService,
  returnCantidadService,
  getCalculo_price_with_cupon,
  get_valor_por_nombre_periodicidad,

} from "@/helpers";
export default {
  props: ["attributes", "detail", "detailServiceConfig", "showNavbar", "back"],
  data: () => ({
    showAttributes: true,
    step_config: 1,
    cupon_query: null,
  }),
  computed: {
    ...mapGetters(["getData"]),
    data_() {
      return this.getData.carrito;
    },
    filterCategory() {
      let categories = [];
      this.detail.subcategory_.adicionales.forEach((value) => {
          if (value.servicios_activos.length > 0) {
            categories.push(value);
          }
      });

      return categories;
    },
    detail_computed(){
      if(this.$route.query.cupon){
        let servicesDetail = {...this.detail};
          if(servicesDetail.cupon_service.periodicidad_state == 1 && !servicesDetail.cupon_service.cupon_periodicidad_selected ){
            let cupon_periodicidad_selected = servicesDetail.cupon_service.all_periodicidades.filter(e=> e.id_periodicidad == this.cupon_query.id_periodicidad );
            servicesDetail.cupon_service.cupon_periodicidad_selected = cupon_periodicidad_selected[0];
          }
        return servicesDetail;
      }else{
        return this.detail;
      }
    }
  },
  created() {
    this.$root.$on("configurationComplet", () => this.configurationComplet());
    if (this.detail.configuracion.length > 0) {
      this.showAttributes = false;
    }
    this.checkResponse();
  },

  methods: {
    formatNumber,
    toArray,
    setPriceService,
    setTotalPriceService,
    setMasterTotalPriceService,
    returnQuantityService,
    returnCantidadService,
    getCalculo_price_with_cupon,
    get_valor_por_nombre_periodicidad,


    ...mapMutations([ "deleteDetail"]),
    returnStepConfg() {
      this.$root.$emit("returnStepConfg");
      $("#panel_1").collapse("show");
      setTimeout(() => {
        this.step_config = 1;
      }, 50);
    },

        // funcion que toma los datos del pago desde la respuesta URL
    checkResponse() {
      // sin no es una transferencian
      if(this.$route.query.cupon){
         this.cupon_query = {code: this.$route.query.cupon} ;
         if(this.$route.query.cupon_periodicidad) this.cupon_query.id_periodicidad = this.$route.query.cupon_periodicidad;
      }
    },

    configurationComplet() {
      $("#panel_1").collapse("hide");

      this.step_config = 2;
    },
    scrollDown() {
      $([document.documentElement, document.body]).animate(
        {
          scrollTop: $(document).height() - ($(window).height() + 470),
        },
        800
      );
    },

    checkCampos(nombre) {
      if (!nombre) {
        nombre = "-";
      }
      var campo = this.data_.campos_hostname.find((item) => item.name == nombre);

      if (!campo) {
        campo = this.data_.campos_dominio.find((item) => item.name == nombre);
        if (campo) return true;
      } else {
        return true;
      }
      return false;
    },
    // goToCheckout() {
    //   this.$emit("goToCheckout");
    // },
  },
};
</script>
<style scoped>

.box_service{
  position: relative !important;
}
.box_service_btn_deleteService{
    position: absolute;
    top: 0;
    right: 0;
    margin: 1px 10px;
    z-index: 1000;
}


.text_describe_cupon{
  font-size: 20px;
    margin: 10px;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: flex-start;
    margin: 10px 0px;
    color: gray;
    font-weight: 400;
    position: relative;
}


.text_describe_cupon::before{
  content: "";
  position: absolute;
  left: 0px ;
}


.priceAndCupon{
  flex-direction: column;
}
.priceAndCupon_priceBeforeCupon{
  text-decoration: line-through;
  font-size: 12px;
}

/* cupon icon start */

.sparkle {
  position: relative;
  z-index: 10;
  width: 1rem;
  margin-right: 4px;
}

.sparkle .path {
  fill: currentColor;
  stroke: currentColor;
  transform-origin: center;
  color: #3696ce !important;
  animation: path 2.5s linear 1.5s infinite;
}



/* .button:is(:hover, :focus) .sparkle .path {
  animation: path 1.5s linear 0.5s infinite;
} */

.sparkle .path:nth-child(1) {
  --scale_path_1: 1.2;
}
.sparkle .path:nth-child(2) {
  --scale_path_2: 1.2;
}
.sparkle .path:nth-child(3) {
  --scale_path_3: 1.2;
}

@keyframes path {
  0%,
  34%,
  71%,
  100% {
    transform: scale(1);
  }
  17% {
    transform: scale(var(--scale_path_1, 1));
  }
  49% {
    transform: scale(var(--scale_path_2, 1));
  }
  83% {
    transform: scale(var(--scale_path_3, 1));
  }
}
/* cupon icon end */
</style>